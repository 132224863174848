<template>
	<a :href="'tel:' + phoneLink" v-html="phone"></a>
</template>

<script>
	export default {
		data: () => ({
			phone: "8 (843) 239-21-19",
			phoneLink: "88432392119"
		}),
		mounted(){
			this.getPhone()
		},
		methods: {
			getPhone: function(){
				var comp = this;

				var data = this.jsonp("https://ipinfo.io");
				data.then((res) => {
					if(res.region == "Tatarstan Republic"){
						comp.phone = "8 (843) 239-21-19";
						comp.phoneLink = "88432392119";
					}
				});
			},
			jsonp: function(url){
				return new Promise(function(resolve, reject) {
					let script = document.createElement('script')
					const name = "_jsonp_" + Math.round(100000 * Math.random());
					//url formatting
					if (url.match(/\?/)) url += "&callback="+name
					else url += "?callback="+name
					script.src = url;

					window[name] = function(data) {
						resolve(data);
						document.body.removeChild(script);
						delete window[name];
					} 
					document.body.appendChild(script);
				});
			}
		}
	}
</script>